function stickyHeader() {
    var header_width = $('#header .main-header > .container').width(),
        $stickyMenu = $('.desktop-header-version [data-sticky-menu]'),
        $mobileStickyMenu = $('.mobile-header-version [data-mobile-sticky]');
    if ($stickyMenu.length) {
        $stickyMenu.sticky({
            wrapperClassName: 'desktop-sticky-wrapper'
        });

        $('[data-sticky-cart]').html($('[data-header-cart-source]').html());
    }
    if ($mobileStickyMenu.length) {
        $mobileStickyMenu.sticky({
            wrapperClassName: 'mobile-sticky-wrapper'
        });
    }
}

function scrollToTopButton() {
    var $sttb = $('[data-scroll-to-top]');
    var body = $('body');
    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 100) {
            $sttb.fadeIn();
        } else {
            $sttb.fadeOut();
        }
    });

    $('a', $sttb).on('click', function() {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
        // $.smoothScroll({
        //     speed: 500,
        // scrollTarget: "#cms"
        // });
        // return false;
    });
}

function loadPageWithProcessBar() {
    if ($('body.pace-running').length) {
        Pace.start();
    }
}

function ajaxLoadSidebarCategoryTree() {
    let $sbct = $('.js-sidebar-category-tree');
    if ($sbct.length && typeof(varCategoryTreeController) !== 'undefined') {
        $.ajax({
            type: 'GET',
            url: varCategoryTreeController,
            data: {
                //ajax: true,
            },
            success: function(data) {
                $sbct.html(data);
                sidebarCategoryTreeConfig();
            },
            error: function(err) {
                console.log(err);
            }
        });

        // var sidebarCategoryTreeConfig = function() {
        //     let $subcats = $('.js-sidebar-categories');
        //     if ($subcats.length) {
        //         $subcats.find('.js-collapse-trigger').on('click', function() {
        //             if (!$(this).hasClass('opened')) {
        //                 let $p = $(this).closest('.js-sidebar-categories, .js-sub-categories');
        //                 $p.find('.js-sub-categories.expanded').slideUp().removeClass('expanded');
        //                 $p.find('.js-collapse-trigger.opened').removeClass('opened').find('.add, .remove').fadeToggle();
        //             }
        //             $(this).parent().find(' > .js-sub-categories').stop().slideToggle().toggleClass('expanded');
        //             $(this).toggleClass('opened').find('.add, .remove').fadeToggle();
        //         });
        //     }

        //     let currentCatID = $('.js-category-page').data('current-category-id');
        //     if (currentCatID !== 'undefined' && currentCatID !== '') {
        //         let $currentSBCatObj = $('.js-sidebar-categories [data-category-id=' + currentCatID + ']');

        //         $currentSBCatObj.addClass('current');
        //         $currentSBCatObj.parents('li').each(function() {
        //             $(this).children('.js-sub-categories').addClass('expanded').show();
        //             $(this).find(' > .js-collapse-trigger').addClass('opened');
        //             $(this).find(' > .js-collapse-trigger .add').hide();
        //             $(this).find(' > .js-collapse-trigger .remove').show();
        //         });
        //     }
        // };

        var sidebarCategoryTreeConfig = function() {
            let $subcats = $('.js-sidebar-categories');
            if ($subcats.length) {
                $subcats.find('.js-open-trigger').on('click', function() {
                   
                    if ($(this).hasClass('opened')) {
                        $(this).next('.js-sub-categories').removeClass('expanded');
                        $(this).removeClass('opened');
                        $(this).parents('.js-sidebar-category-tree').removeClass('expanded');              
                    }
                    else {                 
                        if ($(this).parents('.category-sub-menu-transformed'))  {
                            $(this).parents('.category-sub-menu-transformed').addClass('sub-subcategory-expanded');
                            $(this).next('.js-sub-categories').addClass('expanded');
                            $(this).addClass('opened');
                            $(this).parents('.js-sidebar-category-tree').addClass('toggleleft');
                        }
                        else {
                            $(this).next('.js-sub-categories').addClass('expanded');
                            $(this).addClass('opened');
                            $(this).parents('.js-sidebar-category-tree').addClass('toggleleft');
                        }
                        
                    }
                  
                });
            }

            $subcats.find('.return-trigger').each(function(){
                $(this).on('click touch', function(){                 
                    $(this).parent('.js-sub-categories').prev('.js-open-trigger').removeClass('opened');
                    console.log($(this).parents('.category-sub-menu-transformed').length);
                    if($(this).parents('.category-sub-menu-transformed').length == 2) {   
                        $(this).parent('.category-sub-menu-transformed').removeClass('expanded');
                        $(this).parents('.category-sub-menu-transformed.sub-subcategory-expanded').removeClass('sub-subcategory-expanded');
                    }
                    else if ($(this).parents('.category-sub-menu-transformed').length == 3) {
                        $(this).parent('.category-sub-menu-transformed').removeClass('expanded');                     
                        $(this).closest('.sub-subcategory-expanded').removeClass('sub-subcategory-expanded');                       
                    }
                    else { 
                        $(this).parent('.js-sub-categories').removeClass('expanded');
                        $(this).parents('.js-sidebar-category-tree').removeClass('toggleleft');
                    }
                   
                });
            });

            
            let currentCatID = $('.js-category-page').data('current-category-id');
            // if (currentCatID !== 'undefined' && currentCatID !== '') {
            //     console.log('here');
            //     let $currentSBCatObj = $('.js-sidebar-categories [data-category-id=' + currentCatID + ']');

            //     $currentSBCatObj.addClass('current');
            //     $currentSBCatObj.parents('li').each(function() {
            //         $(this).children('.js-sub-categories').addClass('expanded').show();
            //         $(this).find(' > .js-open-trigger').addClass('opened');
            //         $(this).find(' > .js-open-trigger .add').hide();
            //         $(this).find(' > .js-open-trigger .remove').show();
            //     });
            // }
        };
    }
}

function loadSidebarNavigation() {
    if ($('[data-st-menu]').length) {
        setTimeout(function() {
            ajaxLoadSidebarCategoryTree();
        }, 1200);
        $('#js-header-phone-sidebar').removeClass('js-hidden').html($('.js-header-phone-source').html());
        $('#js-account-sidebar').removeClass('js-hidden').html($('.js-account-source').html());
        $('#js-language-sidebar').removeClass('js-hidden').html($('.js-language-source').html()).find('.l-name').remove();
        if (!$('[data-st-cart]').length) {
            $('#js-left-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html());
        }

        $('[data-left-nav-trigger]').on('click', function(e) {
            $('html').addClass('st-effect-left st-menu-open');
            return false;
        });
        $('[data-close-st-menu]').on('click', function(e) {
            $('html').removeClass('st-menu-open st-effect-left');
        });
    }
}

function loadSidebarCart() {
    if ($('[data-st-cart]').length) {
        $('#js-cart-sidebar').removeClass('js-hidden').html($('[data-shopping-cart-source]').html());
        $('[data-shopping-cart-source]').addClass('js-hidden');
        $.each($('#js-cart-sidebar input[name="product-sidebar-quantity-spin"]'), function (index, spinner) {
            $(spinner).makeTouchSpin();

            $(spinner).on('change', function () {
                $(spinner).trigger('focusout');
            });
        });

        $('#js-currency-sidebar').removeClass('js-hidden').html($('.js-currency-source').html()).find('.c-sign').remove();

        $('[data-sidebar-cart-trigger]').on('click', function(e) {
            $('html').addClass('st-effect-right st-menu-open');
            return false;
        });
        $('[data-close-st-cart]').on('click', function(e) {
            $('html').removeClass('st-menu-open st-effect-right');
        });
    }
}

function handleCookieMessage() {
    var $cookieMsg = $('.js-cookieMessage');
    if ($cookieMsg.length) {
        setTimeout(function(){
            $cookieMsg.cookieBar({
                closeButton : '.js-cookieCloseButton',
                path: prestashop.urls.base_url.substring(prestashop.urls.shop_domain_url.length)
            });
        }, 2000);
    }
}

function lazyItemMobileSliderScroll() {
  $('.js-items-mobile-slider').each(function() {
    $(this).on('scroll', function () {
      if($(this).scrollLeft()) {
        $('img.js-lazy', $(this)).trigger('appear');
      }
    })
  });
}

loadPageWithProcessBar();

$(window).on('load', function() {
    handleCookieMessage();
    setTimeout(function(){
        $('img.js-lazy').lazyload({
            failure_limit: 9999,
            load : function(el, s) {
                $(this).removeClass('js-lazy');
            }
        });
    }, 1000);
    
    stickyHeader();
    scrollToTopButton();
    loadSidebarNavigation();
    loadSidebarCart();
    lazyItemMobileSliderScroll();
});