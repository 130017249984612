import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  moveProductAttributes();
  addAccordionActiveClass();

  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    moveProductAttributes();

    if (event && event.product_minimal_quantity) {
      //const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      //const quantityInputSelector = prestashop.selectors.quantityWanted;
      //const quantityInput = $(quantityInputSelector);

      $(prestashop.selectors.quantityWanted).trigger('touchspin.updatesettings', {min: parseInt(event.product_minimal_quantity, 10)});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');

    if ($('[data-button-action="add-to-cart"]').is(':disabled')) {
      $('.product-add-to-cart').addClass('add-to-cart-disabled');
    } else {
      $('.product-add-to-cart').removeClass('add-to-cart-disabled');
    }
  });

  // varCustomActionAddToCart
  handleAddToCart();  
  
});

var productResizeTimer;
$(window).on('resize', function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

// overwrite 'add-to-cart'
function handleAddToCart() {
  $('body').off('click', '[data-button-action="add-to-cart"]');
  $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
    event.preventDefault();
    let $btn = $(this);

    if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
      $btn.attr('disabled', 'disabled');
    } else {
      let $form = $(event.target).closest('form');
      let query = $form.serialize() + '&add=1&action=update';
      let actionURL = $form.attr('action');

      let isQuantityInputValid = function($input) {
        let validInput = true;

        $input.each(function (index, input) {
          let $input = $(input);
          let minimalValue = parseInt($input.attr('min'), 10);
          if (minimalValue && $input.val() < minimalValue) {
            onInvalidQuantity($input);
            validInput = false;
          }
        });

        return validInput;
      };

      let onInvalidQuantity = function($input) {
        $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
        $input.parent().find('label').addClass('error');
      };

      let $quantityInput = $form.find('input[min]');
      if (!isQuantityInputValid($quantityInput)) {
        onInvalidQuantity($quantityInput);

        return;
      }

      $btn.removeClass('added').addClass('disabled');

      $.post(actionURL, query, null, 'json').then(function(resp) {
        prestashop.emit('updateCart', {
          reason: {
            idProduct: resp.id_product,
            idProductAttribute: resp.id_product_attribute,
            idCustomization: resp.id_customization,
            linkAction: 'add-to-cart',
            cart: resp.cart
          },
          resp
        });

        if (resp.success) {
          if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
            $btn.addClass('added');
          } else {
            window.location.href = prestashop.urls.pages.cart + '?action=show';
          }
        }

      }).fail(function (resp) {
        prestashop.emit('handleError', { eventType: 'addProductToCart', resp });
      });
    }
  });
}


function togglePendingRefreshIcon(fade_status) {
  // varProductPendingRefreshIcon
  if (fade_status == 'in') {
    $('.js-product-refresh-pending-query').fadeIn();
  } else {
    $('.js-product-refresh-pending-query').fadeOut();
  }
}

function zoomImage() {
  $('#product .zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('#product .zoomContainer').remove();

  $('#product .js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 300,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  const $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    let zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
      },
      image: {
        verticalFit: false,
        titleSrc: function(item) {
          if (item.el.attr('data-title')) {
            return '<span>' + item.el.attr('data-title') + '</span>';
          } else {
            return '';
          }
        }
      }
    });

    $('.js-mfp-button').on('click', function() {
      //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + $('#js-zoom-gallery .js-thumb.selected').data('id-image') + '"]').trigger('click');
    });

    $('.js-mfp-button-slider').on('click', function() {
      //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      if( $('.gallery-thumbs .swiper-slide').length == 1 ) {
        $('.js_mfp_gallery_item').filter('[data-id-image="' + $('.gallery-thumbs .swiper-slide').data('id-image') + '"]').trigger('click');
      } else {
        $('.js_mfp_gallery_item').filter('[data-id-image="' + $('.gallery-thumbs .swiper-slide-thumb-active').data('id-image') + '"]').trigger('click');
      }
    });
  }
}

function coverImage() {
  $('#product .js-cover-image .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
    }
  );
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
}

function createInputFile() {
  $(prestashop.themeSelectors.fileInput).on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $(prestashop.selectors.quantityWanted);
  $quantityInput.makeTouchSpin();

  $(prestashop.themeSelectors.touchspin).off('touchstart.touchspin');

  $quantityInput.on('focusout', function () {
    if ($quantityInput.val() === '' || $quantityInput.val() < $quantityInput.attr('min')) {
      $quantityInput.val($quantityInput.attr('min'));
      $quantityInput.trigger('change');
    }
  });

  $('body').on('change keyup', prestashop.selectors.quantityWanted, function (e) {
    if ($quantityInput.val() !== '') {
      $(e.currentTarget).trigger('touchspin.stopspin');
      prestashop.emit('updateProduct', {
        eventType: 'updatedProductQuantity',
        event: e,
      });
    }
  });
}

function moveProductAttributes() {
  let $src = $('.js-product-attributes-source'),
      $manufacturer = $('.js-product-attributes-source .product-manufacturer'),
      $ean = $('.js-product-attributes-source .product-specific-references'),
      $reference = $('.js-product-attributes-source .product-reference'),
      $dest = $('.js-product-attributes-destination'),
      $src2 = $('.js-product-availability-source'),
      $dest2 = $('.js-product-availability-destination'),
      $dest3 = $('.js-product-attributes-ref-destination');

  $dest.empty();
   if (($src.length && $('.main-product-details-wrapper').hasClass('product-page--mobile')) || ($src.length && $('#quickviewProduct').hasClass('quickview-mobile'))) {
     $dest.html($src.html()); //$src.remove();
   } else
    {
    if ($manufacturer.length || $ean.length) {
        var man = '',
            ean = '';
        if ($manufacturer.length > 0) {
           man = $manufacturer.prop('outerHTML')
        }
        if ($ean.length > 0) {
            ean = $ean.prop('outerHTML')
         }
      $dest.html(man + ean); //$src.remove();
    }

    $dest3.empty();
    
    if ( $reference.length) {
     let $lang_code = prestashop.language.iso_code,
         $ref_code = $reference.find('span').prop('outerHTML'),
         $ref_text;
      if ($lang_code == 'it') {
         $ref_text = "Rif"
      }
      else {
          $ref_text = "Ref"
      }
      $dest3.html(`<label>${$ref_text}</label> ${$ref_code}`); //$src.remove();
    }
  
    $dest2.empty();
    if ($src2.length) {
      $dest2.html($src2.html()); //$src2.remove();
    }
   }
  
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').on('click', function() {
    $(this).closest('.panel').toggleClass('active');
  });
}
