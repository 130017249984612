function updateDropdownPosition()
{
  var $amegamenu = $('#amegamenu');
  if ($amegamenu.length) {
    if ($amegamenu.hasClass('amegamenu_rtl')) {
      newUpdateDropdownPositionRTL($amegamenu);
    }
    else {
      newUpdateDropdownPosition($amegamenu);
    }

    //updatePosition();
    //$(window).on('resize', updatePosition);
  }
}

function newUpdateDropdownPosition(megamenu)
{
  let mm_width = megamenu.outerWidth();

  $('.adropdown', megamenu).each(function() {
    let dropdown = $(this),
        menu = dropdown.parent('.amenu-item'),
        d_width = dropdown.outerWidth();

    if (mm_width > d_width) {
      let mid = ((mm_width - d_width) / 2),
          gap = ((mm_width - menu.outerWidth()) / 2) - (menu.position().left + parseFloat(menu.css('margin-left')));
      
      if (mid > gap) {
        if (mid - gap + d_width > mm_width) {
          dropdown.css('margin-left', mm_width - d_width + 'px');
        } else {
          dropdown.css('margin-left', mid - gap + 'px');
        }
      } else {
        dropdown.css('margin-left', '0px');
      }
    }
  
  });
}
function newUpdateDropdownPositionRTL(megamenu)
{
  let mm_width = megamenu.outerWidth();

  $('.adropdown', megamenu).each(function() {
    let dropdown = $(this),
        menu = dropdown.parent('.amenu-item'),
        d_width = dropdown.outerWidth();

    if (mm_width > d_width) {
      let mid = ((mm_width - d_width) / 2),
          gap = ((mm_width - menu.outerWidth()) / 2) - (mm_width - menu.position().left - menu.outerWidth() - parseFloat(menu.css('margin-right')));
     
      if (mid > gap) {
        if (mid - gap + d_width > mm_width) {
          dropdown.css('margin-right', mm_width - d_width + 'px');
        } else {
          dropdown.css('margin-right', mid - gap + 'px');
        }
      } else {
        dropdown.css('margin-right', '0px');
      }
    }
  
  });
}

function updateDropdownPositionLTR(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent('.amenu-item'),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_left = p.offset().left,
        margin = parseInt(t.css('margin-left'));
          
    if ( t_right - margin + i > m_right) {
      t.css('margin-left', (m_right - p_left - t.outerWidth()) + 'px');
    } else if (t.offset().left - margin + i < m_left) {
      t.css('margin-left', (m_left - p_left) + 'px');
    } else {
      t.css('margin-left', i + 'px');
    }
  });
}
function updateDropdownPositionRTL(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent(),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_right = p.offset().left + p.outerWidth(),
        margin = parseInt(t.css('margin-right'));
  
    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function mobileToggleEvent()
{
    $('#mobile-amegamenu .amenu-item.plex > .amenu-link').on('click', function() {
        if (!$(this).hasClass('expanded')) {
            $('#mobile-amegamenu .expanded').removeClass('expanded').next('.adropdown').slideUp();
        }
        $(this).next('.adropdown').stop().slideToggle();
        $(this).toggleClass('expanded');

        return false;
    });
}

function enableHoverMenuOnTablet()
{
    $('html').on('touchstart', function(e) {
        $('#amegamenu .amenu-item').removeClass('hover');
    });
    $('#amegamenu').on('touchstart', function (e) {
        e.stopPropagation();
    });
    $('#amegamenu .amenu-item.plex > .amenu-link').on('touchstart', function (e) {
        'use strict'; //satisfy code inspectors        
        var li = $(this).parent('li');
        if (li.hasClass('hover')) {
            return true;
        } else {
            $('#amegamenu .amenu-item').removeClass('hover');
            li.addClass('hover');
            e.preventDefault();
            return false;
        }
    });
}

function ajaxLoadDrodownContent()
{
  var $ajaxmenu = $('.js-ajax-mega-menu');
  if ($ajaxmenu.length && typeof(varMenuDropdownContentController) !== 'undefined') {
    $.ajax({
      type: 'GET',
      url: varMenuDropdownContentController,
      data: {
        //ajax: true,
      },
      dataType: 'json',
      success: function(data) {
        updateDrodownContent(data);
      },
      error: function(err) {
        console.log(err);
      }
    });

    var updateDrodownContent = function(dropdown) {
      $('.js-dropdown-content', $ajaxmenu).each(function () {
        let item = $(this).data('id-menu');
        $(this).replaceWith(dropdown[item]);
      });
    };
  }
}

function setCurrentMenuItem()
{
  if (typeof(varBreadcrumbLinks) !== 'undefined') {
    $('.js-ajax-mega-menu .amenu-item').each(function () {
      if ($.inArray($(this).find('a.amenu-link').attr('href'), varBreadcrumbLinks) != -1) {
        $(this).addClass('curr-menu');
      }
    });
  }
}

$(window).on('load', function() {
  setTimeout(function() {
    ajaxLoadDrodownContent();
    updateDropdownPosition();
  }, 600);

  setCurrentMenuItem();
  mobileToggleEvent();
  enableHoverMenuOnTablet();
});